import React from "react";
import "../assets/css/404.scss";
import Head from "../components/head";
import Layout from "../components/layout";
import image404 from "../images/404.png";

function WrongPage({ pageContext }) {
  return (
    <Layout contactUs={false} initial={false}>
      <div className="wrongPage__container">
        <img src={image404} className="wrongPage__image" />
        <h1 className="wrongPage__title">
          {
            {
              en: "Sorry, page not found",
              pl: "Przepraszamy, nie znaleziono strony",
            }[pageContext.intl.language]
          }
        </h1>
      </div>
    </Layout>
  );
}

export default WrongPage;
